body {
  font-size: 1em;
  line-height: 1.5em;
  font-family: "Georgia W01 Regular";
  color: #444;
  padding: 0 0 50px;
  background-image: url(./images/invisible-ink.jpg);
  background-repeat: no-repeat;
  background-position: -650px 0%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
